// @ts-check
import React from "react"
import SharedWhyChooseUsContent from "./SharedWhyChooseUsContent"

const ServicesWhyChooseUs = () => {
  return (
    <div className="wrapper gray-wrapper">
      <div className="container inner">
        <div className="row align-items-center">
          <div className="col-lg-7 order-lg-2 text-center">
            <figure>
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept23.png 1x, /snowlake/images/concept/concept23@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/*/column */}
          <div className="space20 d-md-none" />
          <div className="space50 d-none d-md-block d-lg-none" />
          <div className="col-lg-5 pr-60 pr-md-15">
            <SharedWhyChooseUsContent alt />
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </div>
  )
}

export default ServicesWhyChooseUs
