// @ts-check
import React from "react"
import { Link } from "gatsby"
import ContactUsForm from "./ContactUsForm"

const LetsTalk = () => {
  return (
    <div className="wrapper white-wrapper">
      <div className="container inner">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <figure>
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept2.png 1x, /snowlake/images/concept/concept2@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/*/column */}
          <div className="space50 d-none d-md-block d-lg-none" />
          <div className="space10 d-md-none" />
          <div className="col-lg-6 pl-60 pl-md-15">
            <h2 className="title-color color-gray">Let's Talk</h2>
            <h3 className="display-3">
              MVP, Startup or Enterprise - we're here for you.
            </h3>
            <div className="space20" />
            <p>
              Let’s discuss your vision and goals and how we can help you
              realise your next project.
            </p>
            <div className="space10" />
            <Link to="/contact" className="btn btn-sky">
              Contact Us
            </Link>
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </div>
  )
}

export default LetsTalk
