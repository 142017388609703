// @ts-check
import React from "react"

const ServicesFAQ = () => {
  return (
    <div className="wrapper light-wrapper">
      <div className="container inner pb-0">
        <h3 className="display-3 text-center">
          If you don't see an answer to your question,{" "}
          <br className="d-none d-lg-block" />
          you can send us an email from our contact form
        </h3>
        <div className="space40" />
        <div className="row">
          <div className="col-lg-6 mb-0">
            <div id="accordion-1" className="accordion-wrapper">
              <Card
                question="What technology do you build in?"
                answer="We're experts in React Native, a framework out of Facebook for
            quickly building high quality mobile apps."
                accordionId="#accordion-1"
                index="1"
              />
              <Card
                question="How long will it take?"
                answer="This will vary based on your vision and which one of our
                packages you choose. For simple apps we aim to deliver
                within a 2-4 week time scale."
                accordionId="#accordion-1"
                index="2"
              />

              <Card
                question="What about backend development?"
                answer="We can deliver an API backend to drive your applications,
                including common features such as Social Auth. We use Ruby
                on Rails and Node.js stacks"
                accordionId="#accordion-1"
                index="3"
              />
            </div>
            {/* /.accordion-wrapper */}
          </div>
          {/*/column */}
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion-wrapper">
              <Card
                question="How involved do I need to be?"
                answer="We have a commitment to making our customers happy - we
                can discuss how much, or how little, you'd like to be
                actively involved in the development process. Regardless,
                we will always make every effort to keep you updated with
                regular progress updates."
                accordionId="#accordion-2"
                index="4"
              />

              <Card
                question="I need a website too"
                answer="We don't typically do websites on their own, but if we're
                              building apps out for you and need a launch website, we're
                              more than happy to help."
                accordionId="#accordion-2"
                index="5"
              />

              <Card
                question="I already have an app, but it needs some changes"
                answer="We're more than happy to help update an existing codebase
                - our preferred tech stack is React Native, although we do
                not shy away from native extensions where required."
                accordionId="#accordion-2"
                index="6"
              />
            </div>
            {/* /.accordion-wrapper */}
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
        <div className="space120" />

        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  )
}

const Card = ({ question, answer, index, accordionId }) => {
  const headingId = `heading-${index}`
  const collapseId = `collapse-${index}`
  return (
    <div className="card shadow">
      <div className="card-header" id={headingId}>
        <h5>
          <button
            className="collapsed"
            data-toggle="collapse"
            data-target={"#" + collapseId}
            aria-expanded="false"
            aria-controls={collapseId}
          >
            {question}
          </button>
        </h5>
      </div>
      {/* /.card-header */}
      <div
        id={collapseId}
        className="collapse"
        aria-labelledby={headingId}
        data-target={accordionId}
      >
        <div className="card-body">
          <p>{answer}</p>
        </div>
        {/* /.card-body */}
      </div>
      {/* /.collapse */}
    </div>
  )
}

export default ServicesFAQ
