// @ts-check
import React from "react"
import Layout from "../components/layout/Layout"
import ServicesOurServices from "../partials/ServicesOurServices"
import ServicesHowItWorks from "../partials/ServicesHowItWorks"
import ServicesWhyChooseUs from "../partials/ServicesWhyChooseUs"
import ServicesLetsTalk from "../partials/ServicesLetsTalk"
import ServicesFAQ from "../partials/ServicesFAQ"

const ServicesPage = () => {
  return (
    <Layout title="Services">
      <div className="d-xs-block space60"></div>
      <ServicesOurServices />
      <ServicesHowItWorks />
      <ServicesWhyChooseUs />
      <ServicesFAQ />
      <ServicesLetsTalk />
    </Layout>
  )
}

export default ServicesPage
