// @ts-check
import React from "react"

const ServicesOurServices = () => {
  return (
    <div className="wrapper gray-wrapper">
      <div className="container inner">
        <h2 className="section-title larger text-center">Our Services</h2>
        <h3 className="display-4 text-center">
          {/* <br className="d-none d-lg-block" /> */}
          We specialise in React Native apps - development or consultancy
        </h3>
        <div className="space50" />
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <figure>
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept26.png 1x, /snowlake/images/concept/concept26@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
        <div className="space50" />
        <div className="row gutter-80 gutter-md-30 text-center">
          <div className="col-md-4">
            <span className="icon icon-blob icon-blob-yellow color-yellow mb-30">
              <i className="jam jam-flower" />
              <span className="step bg-yellow">1</span>
            </span>
            <h5>Mobile App Development</h5>
            <p className="mb-15">
              iOS and Android apps, built with React Native, the modern way to
              build apps quickly and efficiently.
            </p>
            {/* <div className="text-center">
              <a href="#" className="more">
                Learn more
              </a>
            </div> */}
          </div>
          {/*/column */}
          <div className="col-md-4">
            <span className="icon icon-blob icon-blob-purple color-purple mb-30">
              <i className="jam jam-phone" />
              <span className="step bg-purple">2</span>
            </span>
            <h5>Backend Development</h5>
            <p className="mb-15">
              Supply API services to accompany the mobile app we build for you,
              including administrative dashboards where required.
            </p>
            {/* <div className="text-center">
              <a href="#" className="more">
                Learn more
              </a>
            </div> */}
          </div>
          {/*/column */}
          <div className="col-md-4">
            <span className="icon icon-blob icon-blob-teal color-teal mb-30">
              <i className="jam jam-layout" />
              <span className="step bg-teal">3</span>
            </span>
            <h5>Technical Advisory Services</h5>
            <p className="mb-15">
              We realise sometimes you need experienced engineers to help mentor
              a junior team or when switching technology stacks. We can augment
              into your teams to boost your capability.
            </p>
            {/* <div className="text-center">
              <a href="#" className="more">
                Learn more
              </a>
            </div> */}
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </div>
  )
}

export default ServicesOurServices
