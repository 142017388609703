// @ts-check
import React from "react"

const SlideRight = ({ children, delay = 300, duration = 300 }) => {
  return (
    <div
      data-sal="slide-right"
      data-sal-delay={delay}
      data-sal-duration={duration}
      data-sal-easing="ease-in"
    >
      {children}
    </div>
  )
}

export default SlideRight
