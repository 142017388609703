// @ts-check
import React from "react"
import SlideRight from "../components/SlideRight"

const ServicesHowItWorks = () => {
  const stepsContent = [
    {
      title: "Research & Concept",
      body: `We use Design Thinking to help refine your idea, and help you
	understand the underlying technology and estimate the effort involved`,
    },
    {
      title: "Explore & Prototype",
      body: `Investigate ideas and user experiences, - together we solidify the concept.
		The goal of this phase is to find the most effective flows for your product`,
    },
    {
      title: "Let's build!",
      body: `We'll start building the app. We'll provide regular updates
		and you'll be able to regularly review the project's
		progress if you wish to do so.`,
    },
    {
      title: "Deployment & Maintenance",
      body: `Once your product is ready, we can shepherd it through the app review
		process and push it live! Alternatively we can handover to your internal teams,
		and guide you step by step through the process.`,
    },
  ]

  return (
    <div className="wrapper light-wrapper">
      <div className="container inner">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <figure>
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept25.png 1x, /snowlake/images/concept/concept25@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/*/column */}
          <div className="space20 d-md-none" />
          <div className="space50 d-none d-md-block d-lg-none" />
          <div className="col-lg-6 pl-60 pl-md-15">
            <h2 className="section-title larger">How It Works</h2>
            {/* <h3 className="display-4">
              Here are 3 working steps to organize our business projects.
            </h3> */}
            <div className="space30" />
            {stepsContent.map((step, index) => (
              <SlideRight delay={index * 300}>
                <NumberedSteps
                  count={index + 1}
                  title={step.title}
                  body={step.body}
                />
                <div className="space10 d-none d-md-block" />
              </SlideRight>
            ))}
          </div>
          {/*/.row */}
        </div>
        {/*/column */}
      </div>
      {/*/.row */}
    </div>
  )
}

const NumberedSteps = ({ count, title, body }) => {
  return (
    <div className="d-flex flex-row justify-content-center">
      <div>
        <div className="icon icon-bg icon-bg-teal color-teal mr-25">
          <span className="number">{count}</span>
        </div>
      </div>
      <div>
        <h4 className="mb-10">{title}</h4>
        <p>{body}</p>
      </div>
    </div>
  )
}

export default ServicesHowItWorks
